// Imports your configured client and any necessary S3 commands.
import { PutObjectCommand } from '@aws-sdk/client-s3';
import configuration from '../config/Config';
import { UPLOAD_URL } from '../config/Api';
import { s3Client } from '../config/CmdsDigitalOcean';

// // Specifies a path within your bucket and the file to upload.
// export let bucketParams = {
//     Bucket: `${configuration.bucketName}`,
//     Key: "example.txt",
//     Body: "content"
// }

// Uploads the specified file to the chosen path.
export const cmdsFileUpload = async (uploadedFile, setFilePath, name) => {
  // Specifies a path within your bucket and the file to upload.
  let bucketParams = {
    Bucket: `${configuration.bucketName}`,
    Key: 'example.txt',
    Body: 'content',
  };

  let user_id = localStorage.getItem('user_id');
  let userName = localStorage.getItem('userName');

  let resultantName = userName?.replaceAll(' ', '');

  let newDate = new Date();
  let date =
    newDate.getDate() +
    '-' +
    (newDate.getMonth() + 1) +
    '-' +
    newDate.getFullYear() +
    '-' +
    newDate.getHours() +
    '-' +
    newDate.getMinutes() +
    '-' +
    newDate.getSeconds();
  let milliSeconds = newDate.getMilliseconds();

  let rootFile = 'stucle-cmds';
  let folderName = name === 'playkit_tool_thumbnail' ? 'play-kit' : 'content';

  bucketParams.Body = uploadedFile;
  bucketParams.ACL = 'public-read';

  let fileName = uploadedFile.name.replaceAll(' ', '');

  bucketParams.Key = `${rootFile}/${folderName}/${user_id}-${resultantName}/${date}-${milliSeconds}${name ? '-' + name : ''}-${fileName}`;
  // console.log('bucketParams.Key', bucketParams, bucketParams.Key);
  let filePath = `${UPLOAD_URL}` + bucketParams.Key;

  try {
    // eslint-disable-next-line
    const data = await s3Client.send(new PutObjectCommand(bucketParams));
    // eslint - to remove keyword(data) usage warning
    setFilePath(filePath);
    return filePath;
  } catch (err) {
    console.log('Error', err);
    throw err;
  }
};
