import React, { useState, useEffect, useRef } from 'react';
import './AddPlayKit.css';
import { Link, useHistory } from 'react-router-dom';
import RiseLoader from 'react-spinners/RiseLoader';
import '../../../../Assets/css/common.css';
import { ToastContainer, toast, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import ArrowLeft from '../../../../Assets/ContentLibrary/UploadContent/arrow-left.png';
import PlayKitTextInput from '../../../../components/PlayKitTextInput/PlayKitTextInput';
import ToolModal from '../../../../components/ToolModal/ToolModal';
import ToolControlButtons from '../../../../components/ToolControlButtons/ToolControlButtons';
import ToolCard from '../../../../components/ToolCard/ToolCard';
import { addPlayKit, editPlayKit, addTool, editTool, deleteTool } from '../../../../api/playKit';
import { PLAYKIT_NAME_LENGTH, PLAYKIT_DESCRIPTION_LENGTH } from '../../../../utils/playKitConstants';

const AddPlayKit = (props) => {
  const { toggleEditComponent, playKitDetails } = props;
  const history = useHistory();

  if (toggleEditComponent && !playKitDetails) {
    toast.error('Something wrong with selected Playkit');
    setTimeout(() => {
      history.push('/playKit');
    }, 3000);
  }

  const storedToolArray = JSON.parse(localStorage.getItem('storedToolArray'));
  const [loading, setLoading] = useState(false);
  const [playKit, setPlayKit] = useState({
    playKit_name: toggleEditComponent ? playKitDetails?.playkit_name : '',
    playKit_description: toggleEditComponent ? playKitDetails?.playkit_description : '',
  });
  const [formErrors, setFormErrors] = useState({
    playKit_name: '',
    playKit_description: '',
  });
  const [localToolList, setLocalToolList] = useState(
    toggleEditComponent
      ? storedToolArray?.length > 0
        ? storedToolArray
        : playKitDetails.playkit_tools
      : storedToolArray?.length > 0
        ? storedToolArray
        : [],
  );
  const [openModal, setOpenModal] = useState(false);
  const [editToolMode, setEditToolMode] = useState(false);
  const [editToolInfo, setEditToolInfo] = useState({});
  const initialRender = useRef(true);

  const errObj = {};
  const compareLastList = JSON.stringify(localToolList);
  const toolArrLength = localToolList?.length;

  useEffect(() => {
    setLoading(true);
    console.log('localToolList inside useEffect', localToolList);
    if (toggleEditComponent && toolArrLength && initialRender.current) {
      const transformedToolsArr = localToolList?.map((ele) => {
        if (!ele?.newlyAdded) {
          return { ...ele, alreadyExisted: true };
        } else {
          return ele;
        }
      });
      setLocalToolList(transformedToolsArr);
      initialRender.current = false;
    }
    localStorage.setItem('storedToolArray', JSON.stringify(localToolList));
    setLoading(false);
    return () => {
      localStorage.removeItem('storedToolArray');
    };
  }, [compareLastList, toggleEditComponent, toolArrLength]);

  const handleInputChange = (e) => {
    const { value, name } = e.target;
    if (name === 'playKit_name') {
      if (value?.length <= PLAYKIT_NAME_LENGTH) {
        setPlayKit((prevState) => {
          return { ...prevState, playKit_name: value };
        });
        setFormErrors((prevState) => ({ ...prevState, playKit_name: '' }));
        errObj.playKit_name = '';
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          playKit_name: `'Play kit name cannot exceed more than ${PLAYKIT_NAME_LENGTH} characters'`,
        }));
        errObj.playKit_name = `Play kit name cannot exceed more than ${PLAYKIT_NAME_LENGTH} characters`;
      }
    }
    if (name === 'playKit_description') {
      if (value?.length <= PLAYKIT_DESCRIPTION_LENGTH) {
        setPlayKit((prevState) => {
          return { ...prevState, playKit_description: value };
        });
        setFormErrors((prevState) => ({
          ...prevState,
          playKit_description: '',
        }));
        errObj.playKit_description = '';
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          playKit_description: `Play kit description cannot exceed more than ${PLAYKIT_DESCRIPTION_LENGTH} characters`,
        }));
        errObj.playKit_description = `Play kit description cannot exceed more than ${PLAYKIT_DESCRIPTION_LENGTH} characters`;
      }
    }
  };

  const handleAddToolClick = () => {
    setOpenModal(!openModal);
  };

  const toggleEditMode = () => {
    setEditToolMode((prevState) => !prevState);
  };

  const updateLocalToolList = (inComingToolInfo) => {
    setLoading(true);
    if (toggleEditComponent) {
      if (!editToolMode && !inComingToolInfo?.alreadyExisted) {
        setLocalToolList((prevState) => [
          ...prevState,
          { ...inComingToolInfo, tool_id: Date.now(), newlyAdded: true, alreadyExisted: false, edited: false },
        ]);
      } else if (editToolMode && inComingToolInfo.alreadyExisted) {
        const updateList = localToolList.map((ele) => {
          if (ele.tool_id === inComingToolInfo.tool_id) {
            return { ...inComingToolInfo, newlyAdded: false, edited: true, deleted: false };
          } else {
            return ele;
          }
        });
        setLocalToolList(updateList);
      } else {
        const updateList = localToolList.map((ele) => {
          if (ele.tool_id === inComingToolInfo.tool_id) {
            return inComingToolInfo;
          } else {
            return ele;
          }
        });
        setLocalToolList(updateList);
      }
    } else {
      if (!editToolMode) {
        setLocalToolList((prevState) => [...prevState, { ...inComingToolInfo, tool_id: Date.now() }]);
      } else {
        const updateList = localToolList.map((ele) => {
          if (ele.tool_id === inComingToolInfo.tool_id) {
            return inComingToolInfo;
          } else {
            return ele;
          }
        });
        setLocalToolList(updateList);
      }
    }
    setLoading(false);
  };

  const handleEditTool = (editabletool) => {
    setEditToolInfo((prevState) => {
      if (editabletool?.newlyAdded) {
        return {
          ...prevState,
          tool_name: editabletool.tool_name,
          tool_image_url: editabletool.tool_image_url,
          tool_description: editabletool.tool_description,
          tool_id: editabletool.tool_id,
          tool_thumbnail_file: editabletool.toolThumbnail,
          newlyAdded: editabletool?.newlyAdded,
          alreadyExisted: editabletool?.alreadyExisted,
          edited: editabletool?.edited,
          deleted: editabletool?.edited,
        };
      } else if (editabletool?.alreadyExisted) {
        return {
          ...prevState,
          tool_name: editabletool.tool_name,
          tool_image_url: editabletool.tool_image_url,
          tool_description: editabletool.tool_description,
          tool_id: editabletool.tool_id,
          tool_thumbnail_file: editabletool.toolThumbnail,
          alreadyExisted: editabletool?.alreadyExisted,
          newlyAdded: editabletool?.newlyAdded,
          edited: editabletool?.edited,
          deleted: editabletool?.deleted,
        };
      } else {
        return {
          ...prevState,
          tool_name: editabletool.tool_name,
          tool_image_url: editabletool.tool_image_url,
          tool_description: editabletool.tool_description,
          tool_id: editabletool.tool_id,
          tool_thumbnail_file: editabletool.toolThumbnail,
        };
      }
    });
    toggleEditMode();
    setOpenModal(!openModal);
  };

  const handleTextBoxChange = (e, tool) => {
    const flag = e?.target?.checked;
    const updateList = localToolList.map((ele) => {
      if (ele.tool_id === tool.tool_id) {
        return { ...ele, checked: flag };
      } else {
        return ele;
      }
    });
    setLocalToolList(updateList);
  };

  const eraseTool = async (selectedObj) => {
    if (selectedObj?.newlyAdded) {
      const localList = localToolList.filter((ele) => ele.tool_id !== selectedObj.tool_id);
      setLocalToolList(localList);
    } else {
      const localList = localToolList.map((ele) => (ele.tool_id === selectedObj.tool_id ? { ...ele, deleted: true } : ele));
      setLocalToolList(localList);
    }
  };

  const handleRemoveTool = (selectedObj) => {
    confirmAlert({
      message: `Are you sure you want to remove this tool`,
      buttons: [{ label: 'Yes', onClick: () => eraseTool(selectedObj) }, { label: 'No' }],
    });
  };

  const eraseAllTools = () => {
    const updatedList = localToolList.map((ele) => {
      if (ele?.alreadyExisted && ele?.checked) {
        return { ...ele, deleted: true };
      } else if (ele?.newlyAdded && ele?.checked) {
        return null;
      } else return ele;
    });
    const toolFinalList = updatedList.filter((ele) => ele !== null);
    setLocalToolList(toolFinalList);
  };

  const handleBulkRemove = () => {
    const findSelectedTools = localToolList.filter((ele) => ele?.checked);
    if (findSelectedTools?.length) {
      confirmAlert({
        message: `Are you sure you want to remove all tools selected?`,
        buttons: [{ label: 'Yes', onClick: () => eraseAllTools() }, { label: 'No' }],
      });
    } else {
      toast.info('Select all the tools you want to remove');
    }
  };

  const runPlayKitValidations = async () => {
    if (playKit?.playKit_name?.trim().length === 0) {
      errObj.playKit_name = 'Play kit name cannot be empty';
    }
    if (playKit?.playKit_description?.trim().length === 0) {
      errObj.playKit_description = 'Play kit description cannot be empty';
    }
  };

  const postToolsToServer = async (addedPlayKitId) => {
    return await Promise.allSettled(
      localToolList.map(async (ele) => {
        const toolForm = {
          playkit_id: addedPlayKitId,
          tool_name: ele.tool_name,
          tool_image_url: ele.tool_image_url,
          tool_description: ele.tool_description,
        };
        const addToolToPlaykit = await addTool(toolForm);
        return addToolToPlaykit;
      }),
    );
  };

  const initialPlaykitSubmit = async (playKitToUpload) => {
    const addPlayKitResult = await addPlayKit(playKitToUpload);
    const addedPlayKitId = addPlayKitResult?.data?.playkit_id;
    if (addPlayKitResult?.status) {
      if (localToolList.length > 0) {
        const uploadToolsForPlaykit = await postToolsToServer(addedPlayKitId);

        const flagArr = uploadToolsForPlaykit.filter((ele) => ele.status === 'rejected');

        if (flagArr.length === 0) {
          toast.success('Playkit added successfully along with its tools!');
          setTimeout(() => {
            history.push('/playKit');
          }, 3000);
          localStorage.removeItem('storedToolArray');
          setLoading(false);
        } else {
          toast.error('Error occured in PlayKit submision');
          flagArr.forEach((element) => {
            toast.error(element?.reason?.response?.data?.message);
          });
          setLoading(false);
        }
      } else {
        toast.info('Noo tools are added for this playkit');
        toast.success('Playkit added successfully!');
        setTimeout(() => {
          history.push('/playKit');
        }, 3000);
        setLoading(false);
      }
    } else {
      toast.error('Error occured in PlayKit submision');
      setLoading(false);
    }
  };

  const postUpdatedToolsToServer = async (addedPlayKitId) => {
    // console.log('postUpdatedToolsCalling', addedPlayKitId);
    return await Promise.allSettled(
      localToolList.map(async (ele) => {
        const toolForm = {
          playkit_id: addedPlayKitId,
          tool_name: ele.tool_name,
          tool_image_url: ele.tool_image_url,
          tool_description: ele.tool_description,
        };
        if (ele?.alreadyExisted) {
          if (ele?.deleted) {
            const deletedTool = await deleteTool(ele.tool_id);
            console.log('deletedTool', deletedTool);
            return deletedTool;
          }
          if (ele?.edited) {
            const editedTool = await editTool({ ...toolForm, tool_id: ele.tool_id });
            console.log('editedTool', editedTool);
            return editedTool;
          } else return null;
        } else {
          const addToolToPlaykit = await addTool(toolForm);
          console.log('addToolToPlaykit', addToolToPlaykit);
          return addToolToPlaykit;
        }
      }),
    );
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    await runPlayKitValidations();
    if (Object.keys(errObj).length === 0) {
      setFormErrors({});
      const formData = {
        name: playKit.playKit_name,
        description: playKit.playKit_description,
      };
      // const toolFormData = {};
      try {
        const playKitToUpload = toggleEditComponent ? { ...formData, playkit_id: playKitDetails.playkit_id } : formData;
        setLoading(true);
        if (toggleEditComponent) {
          const { playkit_name, playkit_description } = playKitDetails;

          if (playkit_name !== playKit.playKit_name || playkit_description !== playKit.playKit_description) {
            const editPlaykitResult = await editPlayKit(playKitToUpload);
            if (editPlaykitResult?.status) {
              toast.success('Playkit edited successfully!');
              setTimeout(() => {
                history.push('/playKit', { state: { mode: false } });
              }, 3000);
              localStorage.removeItem('storedToolArray');
              setLoading(false);
            } else {
              toast.error('Error occured in PlayKit Edit submision');
              setLoading(false);
            }
          }

          const updateToolsForPlayKit = await postUpdatedToolsToServer(playKitDetails.playkit_id);
          // console.log('updateToolsForPlayKit', updateToolsForPlayKit);

          const flagArr = updateToolsForPlayKit.filter((ele) => ele.status === 'rejected');

          if (flagArr.length === 0) {
            toast.success('Playkit edited successfully along with its tools!');
            setTimeout(() => {
              history.push('/playKit');
            }, 3000);
            localStorage.removeItem('storedToolArray');
            setLoading(false);
          } else {
            toast.error('Error occured in PlayKit submision');
            flagArr.forEach((element) => {
              toast.error(element.reason);
            });
            setLoading(false);
          }
        } else {
          try {
            await initialPlaykitSubmit(playKitToUpload);
          } catch (err) {
            console.log('error in final submission of initialPlaykitSubmit', err);
          }
        }
      } catch (err) {
        console.log('Error in playkit final submission', err);
        toast.error('Error occured in PlayKit submision');
        // setLoading(false);
      }
      setLoading(false);
    } else {
      setFormErrors(errObj);
      toast.error('Please provide required details properly!');
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <div className="loading__spinners">
          <RiseLoader
            color="#CC435F"
            margin={5}
            cssOverride={{
              position: 'absolute',
              top: '50%',
              left: '45%',
              zIndex: 1,
            }}
            loading={loading}
            size={50}
            speedMultiplier={1}
          />
        </div>
      ) : null}
      <div className="playKit-add-content">
        <div className="playKit-add-content__header">
          <Link
            to={{
              pathname: '/playKit',
              state: {
                mode: false,
              },
            }}
            onClick={() => {
              localStorage.removeItem('storedToolArray');
            }}>
            <img className="playKit-header-img" src={ArrowLeft} alt="back to content library" />
          </Link>
          {toggleEditComponent ? (
            <div className="playKit-header-text">Edit Play Kit</div>
          ) : (
            <div className="playKit-header-text">Upload Play Kit</div>
          )}
        </div>
        <div className="playKit-add-content__form-container">
          <form className="playKit-add-content__form" onSubmit={handleSubmit}>
            <div className="playKit-text-holder">
              <PlayKitTextInput
                width={'40%'}
                label={'Name of the Play Kit'}
                value={playKit.playKit_name}
                characters={`${+playKit.playKit_name.length}/${PLAYKIT_NAME_LENGTH - +playKit.playKit_name.length}`}
                name={'playKit_name'}
                handleChange={handleInputChange}
                maxChar={PLAYKIT_NAME_LENGTH}
                errorMessage={formErrors?.playKit_name}
                placeHolder="Enter playkit name"
              />
              <PlayKitTextInput
                width={'60%'}
                label={'Play Kit Description'}
                value={playKit.playKit_description}
                characters={`${+playKit.playKit_description.length}/${PLAYKIT_DESCRIPTION_LENGTH - +playKit.playKit_description.length}`}
                name={'playKit_description'}
                handleChange={handleInputChange}
                maxChar={PLAYKIT_DESCRIPTION_LENGTH}
                errorMessage={formErrors?.playKit_description}
                placeHolder="Enter playkit description"
              />
            </div>
            <div className="playKit-tool-holder">
              <ToolControlButtons
                toolCount={localToolList?.length}
                handleAddToolClick={handleAddToolClick}
                handleDeleteTool={handleBulkRemove}
              />
              <div className="playKit-tool-holder__list">
                {localToolList?.map((ele) => {
                  if (ele?.deleted) {
                    return null;
                  } else {
                    return (
                      <ToolCard
                        tool={ele}
                        key={ele.tool_id}
                        handleRemoveTool={() => handleRemoveTool(ele)}
                        handleEditTool={() => handleEditTool(ele)}
                        handleTextBoxChange={(e) => handleTextBoxChange(e, ele)}
                        toggleEditComponent={toggleEditComponent}
                      />
                    );
                  }
                })}
              </div>
            </div>
            <div className="playKit-form-submission">
              <Link
                to={{
                  pathname: '/playKit',
                  state: {
                    mode: false,
                  },
                }}>
                <button className="playKit-cancel-btn" type="reset">
                  Cancel
                </button>
              </Link>
              <button className="playKit-save-btn" type="submit" onClick={handleSubmit}>
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      {openModal && (
        <ToolModal
          openModal={openModal}
          handleModalOpenClose={handleAddToolClick}
          updateLocalToolList={updateLocalToolList}
          editToolInfo={editToolInfo}
          editToolMode={editToolMode}
          toggleEditMode={toggleEditMode}
        />
      )}
      <ToastContainer
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        transition={Bounce}
      />
    </>
  );
};

export default AddPlayKit;
